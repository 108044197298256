<template>
    <div class="text-center">
		<v-dialog
			v-model="showDialogValorItemEdit"
			transition="dialog-top-transition"
			persistent
			width="500"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Produto [{{ codigoProduto }}]
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
						
					<v-row>
						<v-col 
							cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<label>Informe o novo preço</label>
							<v-text-field
								:prefix="moeda"
								v-model="novoValor"
								v-formatMoney="moneyFormat"
								outlined
							>
							</v-text-field>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 btn-plus dialogButtonCancel"
							outlined
							@click="cancelar()"
						>
							Cancelar
						</v-btn>

						<v-btn
							class="mx-2 btn-plus"
							outlined
							@click="confirmar()"
						>
							Confirmar
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import { formaterDecimalBRServer } from '@/utilities/Utils';

    export default {

        mixins: [Helpers],

        props: {

			id: {
				default: 0
			},

			showDialogValorItemEdit: {
				default: false
			},

			codigoProduto: {
				type: String,
				default: ""
			},

			precoLista: {
				type: String,
				default: "0"
			},

			valor: {
				type: String,
				default: "0"
			},
		},
		
        data: () => ({
			novoValor: 0,

			moeda: "R$",

            moneyFormat: {
                suffix: '',
                thousands: '.',
                decimal: ',',
                precision: 2,
            },
		}),

		watch: {
			"id"() {
				let valorSplit = this.valor.split(' ');

				if (valorSplit != null && valorSplit != undefined) {
					this.moeda = valorSplit[0].toString().trim();
					this.novoValor = valorSplit[1];
				}
				else {
					this.moeda = "R$";
					this.novoValor = "0,00";
				}
			}
		},

        methods: {

            cancelar() {
				this.$emit('update:showDialogValorItemEdit', false)
            },

            confirmar() {

				let precoLista = formaterDecimalBRServer(this.precoLista);
				let novoValor = formaterDecimalBRServer(this.novoValor);

				if (novoValor < precoLista) {
					this.showToast("error", this.$t('aviso'), `Novo preço não pode ser menor que o preço lista [${this.precoLista}].`);
				}
				else {
					this.$emit('methodConfirmToCall', `R$ ${this.novoValor}`);
					this.cancelar();
				}
            }
        }
    };
</script>
